/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("i18n-js");

// Bring in all our jquery plugins:

require('jquery.browser');
require('../application/vendor/jquery.cookie.js');
require('../application/vendor/jquery.template.js');
require('jquery-validation/dist/jquery.validate.min');

require("jquery-ui");
require('tablesorter');
require("../application/vendor/jquery.ui.vertical-tabs.js");

// This comes second to override jquery's built-in autocomplete.
require('devbridge-autocomplete'); 

// This brings in polyfills and bootstrap site-wide.
require('../application');

import I18n from 'i18n-js/index.js.erb'

// From app/assets/javascript/application.js
require("../application/page_loading.js");
require("../application/action_menu.js");
require("../application/debug.js");
require("../application/form_utils.js");
require("../application/group.js");
require("../application/inputclass.js");
require("../application/localization.js");
require("../application/primer.dynamic_row_cloner.js");
require("../application/primer.tooltip.js");
require("../application/timeout.js");
require("../application/util.js");


// This is a global-ish include for all pages where the hospital uses a b. braun pump.
if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_pump'").length > 0) {
  import("../application/b_braun_space_pump/space_pump_utils");
}

// Webpack considers this "dynamic loading," we're basically turning Rails's javascript_include_tag
// on its head.  If you need javascript which only applies to a single page, or a single controller,
// include it in the view using <%= webpack_include_tag(<grouping>) %> and map it to a grouping here.
// 
// Your life will be moderately easier if you keep these sorted alphabetically by the `data-page` attribute.

if(document.querySelectorAll(".webpack_include_tag[data-page='alert']").length > 0) {
  import("../application/alert.js");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='approval_status']").length > 0) {
  import("../application/approval_statuses.js");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_pump_continuous_drug_records']").length > 0) {
  import("../application/b_braun_space_pump/continuous_drug_records.js");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_pump_drug_form_fields']").length > 0) {
  import("../application/b_braun_space_pump/drug_form_fields.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_pump_drug_record_form_fields']").length > 0) {
  import("../application/drug_record_dosing.js")
  import("../application/b_braun_space_pump/drug_record_form_fields.js");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_pump_settings']").length > 0) {
  import("../application/b_braun_space_pump/b_braun_pump_settings.js");
  import("../application/b_braun_space_pump/drug_form_fields.js");
  import("jquery-ui-dist/jquery-ui.theme.min.css");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_two_care_area_edit']").length > 0) {
  import("../application/care_area_edit.js");
  import("../application/b_braun_space_two_pump/care_area_edit.js");
  import("../application/vendor/jquery.ui.vertical-tabs.js");
  import("jquery-ui-dist/jquery-ui.theme.min.css");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_two_drug_record_pump_settings']").length > 0) {
  import("../application/b_braun_space_two_pump/drug_record_pump_settings.js")
  import("../application/drug_record");
  import("../application/drug_record_vote.js");
  import("../application/drug_record_filter.js");
  import("../application/search.js");
  import("../application/pap_alert.js");
  import("../application/hospital_action_menu.js");
  import("../application/vendor/jquery.ui.vertical-tabs.js");
  import("jquery-ui-dist/jquery-ui.theme.min.css");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='b_braun_space_two_pump_settings']").length > 0) {
  import("../application/b_braun_space_two_pump/b_braun_pump_settings.js");
  import("../application/b_braun_space_pump/drug_form_fields.js");
  import("../application/vendor/jquery.ui.vertical-tabs.js");
  import("jquery-ui-dist/jquery-ui.theme.min.css");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='care_area_dialog']").length > 0) {
  import("../application/care_area_dialog.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='care_area_edit']").length > 0) {
  import("../application/care_area_edit.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='care_areas']").length > 0) {
  import("../application/care_areas.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='categories']").length > 0) {
  import("../application/categories.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='concentrations']").length > 0) {
  import("../application/drug_record_dosing.js")
  import("../application/concentrations.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='dashboard']").length > 0) {
  import("../application/dashboard.js");
  import("../application/drug_record_vote.js");
  import("../application/dashboard_list.js");
  import("../application/drug_record_filter");
  import("../application/search.js");
  import("../application/drug_record_status_dash.js");
  import("../application/hospital_action_menu.js");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='dashboard-list']").length > 0) {
  import("../application/dashboard_list.js");
} 

if(document.querySelectorAll(".webpack_include_tag[data-page='deleted_drug_records']").length > 0) {
  import("../application/deleted_drug_records.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='delivery_method']").length > 0) {
  import("../application/delivery_method.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='dialog_add_new_concentrations']").length > 0) {
  import("../application/dialog_add_new_concentrations.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='drug_edit']").length > 0) {
  import("../application/drugs.js");
  import("../application/concentrations.js");
  import("../application/vendor/jquery.ui.vertical-tabs.js");
  import("jquery-ui-dist/jquery-ui.theme.min.css");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='drug_record']").length > 0) {
  import("../application/drug_record.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='drug_record_show']").length > 0) {
  import("../application/drug_record.js");
  import("../application/drug_record_vote.js");
  import("../application/drug_record_filter.js");
  import("../application/search.js");
  import("../application/pap_alert.js");
  import("../application/hospital_action_menu.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='drug_record_filter']").length > 0) {
  import("../application/drug_record_filter.js");
}


if(document.querySelectorAll(".webpack_include_tag[data-page='drug_record_form_fields']").length > 0) {
  import("../application/drug_record_form_fields.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='drug_record_sources']").length > 0) {
  import("../application/drug_record_sources.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='drugs']").length > 0) {
  import("../application/drugs.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='edit_queue']").length > 0) {
  import("../application/edit_queue.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='excluded_emails']").length > 0) {
  import("../application/excluded_emails.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='feature']").length > 0) {
  import("../application/feature.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='hospital']").length > 0) {
  import("../application/hospital.js");
  import("../application/two_factor_required_pumps.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='locations']").length > 0) {
  import("../application/locations.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='modifier']").length > 0) {
  import("../application/modifier.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='non_zynx_drug_record_form_fields']").length > 0) {
  import("../application/non_zynx_drug_record_form_fields.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='pap_alert']").length > 0) {
  import("../application/pap_alert.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='pap_browse']").length > 0) {
  import("../application/pap_browse.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='pump']").length > 0) {
  import("../application/pump.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='pump_manufacturer']").length > 0) {
  import("../application/pump_manufacturer.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='search']").length > 0) {
  import("../application/search.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='session']").length > 0) {
  import("../application/session.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='unit']").length > 0) {
  import("../application/unit.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='users']").length > 0) {
  import("../application/users.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='whats_new']").length > 0) {
  import("../application/whats_new.js");
  import("../application/drug_record_sources.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='workflow']").length > 0) {
  import("../application/workflow.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='zynx_dashboard']").length > 0) {
  import("../application/dashboard_list.js");
  import("../application/zynx_dashboard.js");
  import("../application/drug_record_filter.js");
  import("../application/search.js");
}

if(document.querySelectorAll(".webpack_include_tag[data-page='zynx_drug_record_form_fields']").length > 0) {
  import("../application/zynx_drug_record_form_fields.js");
}

$(document).ready(function() {
  // Rails 5 introduced some fun changes for CSRF tokens, so we prefilter!
  $.ajaxPrefilter(function(options, originalOptions, xhr) {
    if (!options.crossDomain) {
      var token = $('meta[name="csrf-token"]').attr('content');
      if (token) xhr.setRequestHeader('X-CSRF-Token', token);
    }
  });
  // TODO: enable this if we use translated labels for limit fields (1758)
  // $(".drug_record_field_label").tooltip({
  //   position: { my: "left bottom", at: "left top" }
  // });  
})
